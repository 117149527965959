require(['jquery'], function ($) {

    if ($('.xm-grid-category').length) $('html').addClass('page-type-category');

    const sidebar = $('.category-sidebar');
    const main = $('.category-main');

    /// Full Width Main Container if no Sidebar ///
    if (sidebar.text().trim() === "" || $(window).innerWidth() < 960) {
        main.addClass('grid12-12');
        main.removeClass('grid12-9 grid-col2-main');
    }

    /// Add Class to Stock Status Column on Custom Category Grid ///
    $('.products-list .stock-status-box').parent().addClass('stock-status-column');
});